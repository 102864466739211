<script>
import ProgressCircle from "@/components/ProjectItem/ProgressCircle.vue";
import {mapActions, mapGetters} from "vuex";

export  default {
  name : "GroupsAndMuslimsStatistics",
  components: {ProgressCircle},
  props: {
    project:{
      type: Object,
      deep: true,
      default() {
        return {}
      }
    }
  },
  data(){
    return{
      interval: null,
    }
  },
  computed: {
    ...mapGetters("project", ["project_statistics",'get_project_statistics']),
  },
  methods:{
    ...mapActions("project",["getProjectStatistics"]),
    async getProgressData(){
      if(this.get_project_statistics === false){
        clearInterval(this.interval);
      }else{
        //this.interval = setInterval(this.getProgressData, 30000);
      }
      if(this.project.id !== undefined && this.project.is_statistics !== undefined && this.project.is_statistics === true){
       await this.getProjectStatistics({"id":this.project.id});
      }else{
        clearInterval(this.interval);
      }
    }
  },
  mounted() {
    this.getProgressData();
    this.interval = setInterval(this.getProgressData, 30000);
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<template>
  <div class="d-flex justify-content-center gap-5" v-if="project_statistics.length > 0">
        <div class="text-center" v-for="(item, idx) in project_statistics" :key="idx">
          <progress-circle v-if="item.percent > 0"  class="mx-auto" :progress="item.percent" :size="150" :color="item.color" />
          <h2 class="my-1 h5">{{ item.title }}  </h2><b class="edc-color-primary  h2"><count-up :end-val="parseInt(item.count)"/></b>
        </div>
  </div>
</template>

<style scoped lang="css">
</style>