<template>
  <button v-if="is_active" type="button" :data-id="project_data.id" @click.prevent="knetPay(amount, project_data)"
          class=" btn py-3 px-0 ps-1 text-center text-white d-flex justify-content-center align-items-center">
    <img v-if="!is_show_loading" style="height: 45px;" class="rounded img-fluid"  src="@/assets/images/knet.png" data-bs-toggle="tooltip" :title="$t('label.knet')" :alt="$t('label.knet')">
    <div v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{ $t("message.loading") }}</span>
      </div>
    </div>
  </button>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export  default {
  name : "KnetPayButton",
  props: {
    project_data: {
      type: Object,
      deep: true,
      default() {
        return {}
      }
    },
    gift: {
      type: Object,
      deep: true,
      default() {
        return {}
      }
    },
    marketer: {
      type: String,
      deep: true,
    },
    amount: {
      deep: true,
      default : 0
    },

  },
  data() {
    return {
      is_show_loading : false,
      is_active: false,
      knet: {
        payment_type_id : "",
        gateway_type: "",
      },
      gateway : "knet",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("site", ["site_info"]),
  },
  created() {
    this.is_active = this.site_info.payment.knet === "active" || this.site_info.payment.myfatoorah === "active";
  },
  methods:{
    ...mapActions("payment",["fastKnetPayment"]),
    knetPay(amount, project) {
      if (amount <= 0) {
          this.$swal({
            icon: "error",
            title: this.$t("message.must_insert_amount"),
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        return false;
      }else{
        this.is_show_loading = true;
        // call payment by knet
        this.fastKnetPayment({gateway: this.gateway,payment_type_id: this.knet.id,gateway_type: this.knet.type,amount: this.amount,marketer:this.$route.query.mk,gift:this.gift,project: { id: this.project_data['id']}}).then((response) => {
          if (response.data.status === "success") {
            location.href = response.data.url;
          } else {
            this.isLoading = false;
            this.$swal({
              icon: "error",
              title: response.data.msg,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        }).catch((error) => {
              //console.log("Donate" + error);
              this.$swal({
                icon: "error",
                title: error.message,
              });
            }).finally(()=>{
          this.is_show_loading = false;
        });
      }

    },
    DisplayDonateBtn(setting){
      this.is_active = setting.payment.knet === "active" || setting.payment.myfatoorah === "active";
      if(setting.payment.knet === "active" || setting.payment.myfatoorah === "active"){
        if(setting.payment.myfatoorah === "active" && setting.myfatoorah_payment.length > 0 && setting.myfatoorah_payment[0].id === 1){
          this.knet =setting.myfatoorah_payment[0];
          this.gateway = "myfatoorah";
        }else if(setting.payment.knet === "active" ){
          this.gateway = "knet";
        }
      }
    }
  },
  mounted() {
     this.is_active = this.site_info.payment.knet === "active" || this.site_info.payment.myfatoorah === "active";
      if(this.site_info.payment.knet === "active" || this.site_info.payment.myfatoorah === "active"){
          if(this.site_info.payment.myfatoorah === "active" && this.site_info.myfatoorah_payment.length > 0 && this.site_info.myfatoorah_payment[0].id === 1){
            this.knet =this.site_info.myfatoorah_payment[0];
            this.gateway = "myfatoorah";
          }else if(this.site_info.payment.knet === "active" ){
            this.gateway = "knet";
          }
      }
  },
  watch: {
    site_info(newValue, oldValue) {
      this.DisplayDonateBtn(newValue);
    }
  }
}
</script>



<style scoped lang="scss">

</style>