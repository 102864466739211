<script>
import {mapActions} from "vuex";

export  default {
  name : "AddToCart",
  props: {
    project_data: {
      type: Object,
      deep: true,
    },
    amount:{
      type: Number,
      deep: 0,
      default: 1,
    }
  },
  data(){
    return {
      is_loading : false,
    }
  },
  methods:{
    ...mapActions("cart", ["addToCart"]),
    async add_to_cart() {
      this.is_loading = true;
      if (this.amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_amount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }

      this.addToCart({ id: this.project_data.id, amount: this.amount,mk: this.$route.query.mk })
          .then((response) => {

            //console.log(response);
            //this.getCart();
            this.$swal({
              icon: "success",
              title: this.$t("message.add_to_cart_success"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          })
          .catch((error) => {
            if (error) {
              this.$swal({
                icon: "error",
                title: this.$t("message.add_to_cart_fail"),
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
          }).finally(()=>{
            this.is_loading = false;
      });
    },
  }
}
</script>

<template>
  <div>
    <button
        v-if="!is_loading"
        type="button"
        @click.prevent="add_to_cart()"
        class="
        w-100
                addToCart
                btn
                edc-btn-outline-red
                bg-white
                rounded-pill
                btn-lg btn-block
                py-3
                text-center text-muted
                d-flex
                justify-content-center
                align-items-center
              "
    >
      <i class="fas fa-shopping-cart mx-3"></i>
      <span class="fs-6">{{ $t("btn.add_to_cart") }}</span>
    </button>
    <button
        v-else
        type="button"
        disabled
        class="
        w-100
                addToCart
                btn
                edc-btn-outline-red
                bg-white
                rounded-pill
                btn-lg btn-block
                py-3
                text-center text-muted
                d-flex
                justify-content-center
                align-items-center
              "
    >
      <span class="spinner-border text-primary" role="status">
        <span class="sr-only">{{ $t("message.loading") }}</span>
      </span>
      <span class="fs-6">{{ $t("btn.add_to_cart") }}</span>
    </button>
  </div>

</template>

<style scoped lang="css">

</style>