<template>
  <div :style="circleStyle" class="progress-circle">
    <svg :width="size" :height="size" viewBox="0 0 100 100">
      <!-- background -->
      <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          :stroke="options.background_color"
          :stroke-width="options.stroke_width"
      />
      <!-- progress bar -->
      <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          :stroke="color"
          :stroke-width="options.stroke_width"
          stroke-linecap="round"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
          transform="rotate(-90 50 50)"
      />
      <!-- progress value -->
      <text font-weight="bold" v-if="options.display_progress_text" x="70px" y="55px"  :fill="color" font-size="15px" >{{ progress }}%</text>

    </svg>

  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100
    },
    size: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: "blue"
    },

    options: {
      type: Object,
      deep: true,
      default() {
        return {
          display_progress_text : true,
          stroke_width: 10,
          background_color : "#ddd"
        }
      }
    }

  },
  computed: {
    circumference() {
      return 2 * Math.PI * 45;
    },
    offset() {
      return this.circumference * (1 - this.progress / 100);
    },
    circleStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    }
  }
};
</script>

<style scoped>
.progress-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
