<script>
import Integer from "vuelidate/lib/validators/integer";
import FastDonateButton from "@/components/ProjectCardItems/FastDonateButton.vue";
import KnetPayButton from "@/components/ProjectCardItems/KnetPayButton.vue";
import VisaOrMorePayButton from "@/components/ProjectCardItems/VisaOrMorePayButton.vue";

export  default {
  name : "FastDonateArea",
  components: {VisaOrMorePayButton, KnetPayButton, FastDonateButton},
  props: {
    project_data: {
      type: Object,
      deep: true,
      default() {
        return {}
      }
    },
    marketer: {
      type: String,
      deep: true,
    },
    gift:{
      type: Object,
      deep: true,
      default() {
        return {}
      }
    },
    amount: {
      type: Integer,
      deep: 0,
    },

  },
}
</script>

<template>
  <div>
    <div class="px-3"> {{ $t('title.choose_payment_type') }}</div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-start align-items-center">
        <knet-pay-button :project_data="project_data" :amount="amount" :marketer="marketer" :gift="gift"/>
        <visa-or-more-pay-button :project_data="project_data" :amount="amount" :marketer="marketer" :gift="gift" />
      </div>
      <fast-donate-button :project_data="project_data" :amount="amount"  :marketer="marketer"  :gift="gift"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>