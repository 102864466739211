<script>
import Integer from "vuelidate/lib/validators/integer";
import KnetPayButton from "@/components/ProjectCardItems/KnetPayButton.vue";

export  default {
  name : "FastDonateButton",
  props: {
    project_data: {
      type: Object,
      deep: true,
    },
    gift: {
      type: Object,
      deep: true,
      default: {},
    },
    amount: {
      type: Integer,
      deep: 0,
      default: 0,
    },
    marketer: {
      type: String,
      deep: true,
      default: "",
    },
  },
  methods:{
    fastDonate(amount, project) {
      if (amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_amount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.$root.$emit("doFastDonate", { amount: amount, project: project,mk:this.marketer,gift: this.gift });
    },
  }
}
</script>

<template>
  <button
      type="button"
      :data-id="project_data.id"
      @click.prevent="fastDonate(amount, project_data)"
      class="
                fast-donate
                btn
                edc-btn-blue
                rounded-pill
                btn-lg btn-block
                py-3
                text-center text-white
                d-flex
                justify-content-center
                align-items-center
              "
  >
    <i class="fas fa-coins mx-1"></i>
    <div class="fs-7">{{ $t("btn.fast_donate") }}</div>
  </button>
</template>

<style scoped lang="scss">

</style>