<template>
  <div>
    <vue-modal-2 name="ShowFastPaymentTypeModal" :noHeader="false"
                 @on-close="closeFastPaymentTypeModal"
                 :headerOptions="{title: $t('title.choose_payment_type')}"
                 :noFooter="true" style="z-index: 9999">
      <!--donate-modal-form-->
      <div>
        <div v-if="is_show_loading" class="spinner-border text-primary d-block text-center mx-auto" role="status">
          <span class="sr-only">{{ $t("message.loading") }}</span>
        </div>
        <div class=" donate-modal-payments px-3 gap-1 d-flex flex-wrap justify-content-between " >
          <div v-if="site_info.myfatoorah_payment.length > 0 && site_info.payment.myfatoorah === 'active'"
               v-for="mf_payment in site_info.myfatoorah_payment"
               @click.prevent="setGateway(mf_payment.id,`myfatoorah`,mf_payment)"
               :class="{
                      active:
                        select_payment_type_id === mf_payment.id,
                    }"
               class="cursor-pointer payment-type rounded-30 my-1 flex-fill edc-border-primary d-flex justify-content-center align-items-center"
          >
            <label :for="`myfatoorah${mf_payment.id}`" class="py-2 px-3 d-block w-100 text-center">

              <img
                  :src="mf_payment.image"
                  width="30px"
                  height="20px"
                  class="ms-2"
                  alt=""
                  srcset=""
              />
              <input
                  type="hidden"
                  name="payment_type_id"
                  :id="`myfatoorah${mf_payment.id}`"
                  :value="mf_payment.id"
              />
              <span class="fs-9 my-1 d-block fw-bold text-truncate">{{
                  mf_payment.title
                }}</span>
            </label>

          </div>

          <!-- end myfatoorah -->
        </div>
        <div>
        </div>

      </div>
    </vue-modal-2>
    <div class="d-flex justify-content-between align-items-center" >
      <button v-if="is_visa_active" type="button" :data-id="project_data.id" @click.prevent="PayNow(amount, project_data)"
              class=" btn py-3 text-center px-0 ps-1 text-white d-flex justify-content-center align-items-center">
        <img v-if="!is_show_loading"  style="height: 45px;" class="rounded img-fluid"  src="@/assets/images/visa-box.png" data-bs-toggle="tooltip"  :title="$t('label.visa')" :alt="$t('label.visa')" >
        <div v-else class="spinner-border text-primary" role="status">
          <span class="sr-only">{{ $t("message.loading") }}</span>
        </div>
      </button>
      <button v-if="is_myfatoorah_active_visa" type="button" :data-id="project_data.id" @click.prevent="setGateway(myfatoorah_visa.id,`myfatoorah`,myfatoorah_visa)"
              class=" btn py-3 px-0 ps-1 text-center text-white d-flex justify-content-center align-items-center">
        <img v-if="!is_show_loading" style="height: 45px;" class="rounded img-fluid"  src="@/assets/images/visa-box.png" data-bs-toggle="tooltip" :title="$t('label.visa')" :alt="$t('label.visa')" />
        <div v-else class="spinner-border text-primary" role="status">
          <span class="sr-only">{{ $t("message.loading") }}</span>
        </div>
      </button>
      <button v-if="is_myfatoorah_active" type="button"  data-bs-toggle="tooltip" :title="$t('btn.show_all')" @click.prevent="ShowFastPaymentTypeModal()" class=" p-1 edc-btn-secondary rounded-circle text-center text-white d-flex justify-content-center align-items-center">
          <i class="fas fa-ellipsis-h m-2 fa-xl"></i>
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import LoadingPage from "@/components/LoadingPage.vue";
export  default {
  name : "VisaOrMorePayButton",
  components: {LoadingPage},
  props: {
    project_data: {
      type: Object,
      deep: true,
      default() {
        return {}
      }
    },
    gift: {
      type: Object,
      deep: true,
      default() {
        return null
      }
    },
    marketer: {
      type: String,
      deep: true,
    },
    amount: {
      deep: true,
      default : 0
    },

  },
  data() {
    return {
      is_show_loading : false,
      is_visa_active: false,
      is_myfatoorah_active : false,

      select_payment_type_id: 0,
      is_myfatoorah_active_visa : false,
      myfatoorah_visa : {
        id : "",
        type: ""
      },
      payment_method: {
        id : "",
        type: "",
      },
      list : {

      },
      gateway : "visa",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("site", ["site_info"]),
  },

  methods:{
    ...mapActions("payment",["fastKnetPayment"]),
    PayNow() {
        this.is_show_loading = true;
        // call payment by knet
        this.fastKnetPayment({gateway: this.gateway,payment_type_id: this.payment_method.id,gateway_type: this.payment_method.type,amount: this.amount,marketer:this.$route.query.mk,gift:this.gift,project: { id: this.project_data['id']}}).then((response) => {
          if (response.data.status === "success") {
            location.href = response.data.url;
          } else {
            this.isLoading = false;
            this.$swal({
              icon: "error",
              title: response.data.msg,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        }).catch((error) => {
              //console.log("Donate" + error);
              this.$swal({
                icon: "error",
                title: error.message,
              });
            }).finally(()=>{
          this.is_show_loading = false;
        });


    },
    setGateway(id,gatewayName, payment_method) {
      this.gateway = gatewayName;
      this.payment_method  = payment_method;
      this.select_payment_type_id =  id;
      this.is_show_loading = true;
      this.PayNow();
    },
    ShowFastPaymentTypeModal() {
      if (this.amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_amount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }else{
        this.$vm2.open("ShowFastPaymentTypeModal");
      }

    },
    closeFastPaymentTypeModal() {
      this.$vm2.close("ShowFastPaymentTypeModal");
    },
    DisplayDonateBtn(setting){
      this.is_visa_active = setting.payment.visa === "active" ;
      if(setting.payment.visa === "active"){
        this.gateway = "visa";
      }
      if(setting.payment.myfatoorah === "active" && setting.myfatoorah_payment.length > 0){
        this.gateway = "myfatoorah";
        this.list = setting.myfatoorah_payment;
        this.is_myfatoorah_active = true;
      }

      if(setting.payment.myfatoorah === "active" && setting.myfatoorah_payment.length > 0 && setting.myfatoorah_payment[1] !== undefined && setting.myfatoorah_payment[1].id === 2){
        this.gateway = "myfatoorah";
        this.is_myfatoorah_active_visa = true;
        this.myfatoorah_visa = setting.myfatoorah_payment[1];
      }
    }
  },
  mounted() {
    this.DisplayDonateBtn(this.site_info);
  },
  watch: {
    site_info(newValue, oldValue) {
      this.DisplayDonateBtn(newValue);
    }
  }
}
</script>



<style scoped lang="scss">

</style>